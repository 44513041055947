/*=== thread section ===*/
.dashboard_page {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

aside.sidebar {
  background: #1D1A2F;
  height: 100vh;
  flex: 0 0 auto;
  width: 202px;
  left: 0;
  top: 0;
  z-index: 9;
  position: fixed;
  padding: 0 16px 16px;
  overflow: auto;
}

.sidebar_menu .nav_title h6 {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;

}

.sidebar_menu ul li a {
  font-size: 12px;
  cursor: pointer;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 10px;
  color: #fff;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 44px;
  display: block;
  line-height: 26px;
  border-radius: 8px;
  position: relative;
  transition: 0.2s;
}

.sidebar_menu ul li a .trash_img {
  width: 20px;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: center;
}

.sidebar_menu ul li a img {
  width: 15px;
  height: 15px;
  object-fit: contain;
  filter: brightness(100);
}

.sidebar_menu ul li a.active .trash_img,
.sidebar_menu ul li a:hover .trash_img {
  display: flex;
}

.sidebar_menu ul li a.active,
.sidebar_menu ul li a:hover {
  background: rgba(255, 255, 255, 0.25);
  font-weight: 600;
  transition: 0.2s;
  padding-right: 30px;

}

.page_data {
  flex: 0 0 auto;
  width: 100%;
  min-height: autp;
  padding-left: 210px;
}

.page_data {
  min-height: autp;
}

.prompt_detail h3 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.prompt_detail p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22.4px;
  margin: 0;
}

.brainycode_outer {
  padding: 24px;
  background: #EFF1F3;
  border-radius: 32px;
  display: flex;
  align-items: start;
  gap: 20px;
  overflow: hidden;
}

.code_details h3 {
  margin: 0;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.user_info {
  cursor: pointer;
}

.code_details button.btn.btn-primary {
  background: #fff;
  border-color: #fff;
  padding: 8px 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #000;
  gap: 12px;
  border-radius: 24px !important;
  height: auto;
}

.code_details button.btn.btn-primary.fade_btn {
  border: 1px solid #ABABAB;
  background: #EFF1F3;
  color: #ABABAB;
  font-weight: 600;
}

.code_details button.btn.btn-primary.review_done {
  background: transparent;
  border: 1px solid #1D1A2F;
  padding: 4px 8px 4px 4px;
}

.code_details button.btn.btn-primary:not(:first-child) {
  margin-left: 16px;
}

.complete_result p {
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: #000;
}

.complete_result p b {
  font-weight: 600;
}

.complete_result {
  padding-bottom: 21px;
  border-bottom: 2px dashed #000;
}

.code_details button.btn.btn-primary.dark_btn {
  background: #1D1A2F;
  color: #B4F43D;
}

.code code {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22.4px;
}

.tech_container .css-1p3m7a8-multiValue {
  border-radius: 4px;
  background: var(--Colors-True-white, #FFF);
}

.tech_container .css-1hb7zxy-IndicatorsContainer {
  display: none;
}

.tech_container .css-wsp0cs-MultiValueGeneric {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #000;
}

@media (max-width:1400px) {
  section.banner_section {
    height: 90vh;
  }
}

@media (max-width:1370px) {
  section.banner_section {
    height: 110vh;
  }

  .robotics_card {
    height: 279px;
    margin-top: -97px;
  }
}

@media (max-width:1290px) {
  section.banner_section {
    height: 80vh;
  }

  section.generate_code_section {
    padding: 98px 0 20px;
  }
}

@media (max-width:1100px) {
  .banner_section .bg_video video {
    max-height: 100%;
  }
}

@media (max-width:992px) {
  .where_we_shine {
    max-width: 100%;
    margin-bottom: 58px;
  }

  .robotics_card {
    height: 356px;
    margin-top: -125px;
  }

  .robotics_bg img {
    width: 120%;
    margin-left: -10%;
  }

  .center_border:after {
    display: none;
  }

  header ul.navbar-nav .nav-item .nav-link {
    padding: 7.4px 0px;
  }

  header ul.navbar-nav .nav-item .nav-link.active {
    padding: 7.4px 16px;
  }

  header ul.navbar-nav {
    gap: 5px;
  }

  .navbar-collapse {
    background: #fff;
    padding: 9px;
    box-shadow: 0 10px 20px 0 #e4e4e4;
    border-radius: 10px;
    margin-top: 14px;
  }

}

@media (max-width:850px) {
  header {
    top: 24px;
  }

  section.banner_section.generate_banner {
    height: auto;
    min-height: 100vh;
  }
}

@media (max-width:767px) {
  .robotics_card {
    height: 356px;
    margin-top: 16px;
    width: 100%;
    overflow: hidden;
  }

  .robotics_bg img {
    width: 98%;
    margin-left: 4%;
    margin-right: 0;
  }

  .where_we_shine {
    margin-bottom: 28px;
    margin-top: 28px;
  }

  .artifical_content img {
    object-fit: cover;
    object-position: right;
  }

  section.tackling {
    padding: 0px 0;
  }

  footer {
    margin-top: 40px;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
  }

  .footer_heading h4 {
    color: #fff;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }

  br {
    display: none !important;
  }

  .automation_content h4 {
    font-size: 25px;
    line-height: 30px;
  }


  section.banner_section {
    padding: 88px 0;
    height: 107vh;
  }

  .go_back {
    position: relative;
    left: 0;
    top: 0;
    margin: 15px 0;
  }

  .banner_content h1 {
    font-size: 30px;
    line-height: 46px;
  }

  .inner_text h2 {
    font-size: 30px;
    line-height: 46px;
    color: #000;
  }

  .submission_card h3 {
    color: #000;
    font-size: 20px;
    line-height: 28px;
  }
}